import React from 'react'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
export default function SliderAbout() {
    const settings = {
        infinite: true,
        speed: 500,
        dots: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: false,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      };
  return (
    <div>
    <Slider {...settings}>
       <div className='SLIDE-1'>
          <div>
             <img src='/assets/Group 1597883351.png'></img>
          </div>
       </div>
       <div className='SLIDE-2'>
         <div>
           <img src='/assets/Group 1597883352.png'></img>
         </div>
       </div>
       <div className='SLIDE-3'>
         <div>
           <img src='/assets/Group 1597883353.png'></img>
         </div>
       </div>

  </Slider>
  
 
    </div>
  )
}
