import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';


export default function SliderTestimonial() {

  const settings = {
    infinite: true,
    speed: 500,
    dots: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  

  return (
    <div className='text-white'>
      <div className=''></div>
      <Slider {...settings}>
        <div className='px-3'>
          <div className=" bg-black/60 text-white rounded-lg shadow-lg md:h-[456px]">
            <div className='bg-[#185cc2] '>
              <img className="w-full" src="/assets/Test1.png" alt="Slide 3" />
              <img className='-translate-y-3 translate-x-2' src='/assets/Group 1597883324.png' alt="overlay" />
            </div>
            <div className="bg-[#0d2262] py-5 px-5 h-full">
              <div className="flex justify-end items-center">
                <img src='/assets/Group 1 (25).png' alt="icon" />
              </div>
              <div className="flex flex-col gap-5 text-sm mt-4">
                <p className='text-center text-white'>"Exceptional Real-Time Market Analysis and Automation"</p>
                <p className='text-center text-white'>
                  DAB’s automated market monitoring adjusts strategies in real time to maximize returns. It’s like having a precise, always-on trading assistant.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className='px-3'>
          <div className=" bg-black/60 text-white rounded-lg shadow-lg md:h-[456px]">
            <div className='bg-[#185cc2] '>
              <img className="w-full" src="/assets/Test2.png" alt="Slide 1" />
              <img className='-translate-y-3 translate-x-2' src='/assets/Group 1597883324.png' alt="overlay" />
            </div>
            <div className="bg-[#0d2262] py-5 px-5 h-full">
              <div className="flex justify-end items-center">
                <img src='/assets/Group 1 (25).png' alt="icon" />
              </div>
              <div className="flex flex-col gap-5 text-sm mt-4">
                <p className='text-center text-white'>"Lightning-Fast Deployment and Customization"</p>
                <p className='text-center text-white'>
                  The team deployed a fully functional system within days and customized it to our needs. Their speed and attention to detail gave us a significant advantage.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className='px-3'>
          <div className=" bg-black/60 text-white rounded-lg shadow-lg md:h-[456px]">
            <div className='bg-[#185cc2] '>
              <img className="w-full" src="/assets/Test3.png" alt="Slide 2" />
              <img className='-translate-y-3 translate-x-2' src='/assets/Group 1597883324.png' alt="overlay" />
            </div>
            <div className="bg-[#0d2262] py-5 px-5 h-full">
              <div className="flex justify-end items-center">
                <img src='/assets/Group 1 (25).png' alt="icon" />
              </div>
              <div className="flex flex-col gap-5 text-sm mt-4">
                <p className='text-center text-white'>"Incredible Expertise and Support"</p>
                <p className='text-center text-white'>
                  Their deep expertise in DeFi and AI trading was invaluable. With 24/7 support, we never waited for help and always received clear, actionable advice.
                </p>
              </div>
            </div>
          </div>
        </div>
      </Slider>
      
      <style>
        {`
          .slick-dots li button:before {
            font-size: 8px;
            color: rgba(255, 255, 255, 0.5); /* Inactive dots color */
          }
          .slick-dots li.slick-active button:before {
            font-size: 10px;
            color: white; /* Active dot color */
          }
        `}
      </style>
    </div>
  );
}
