import React from 'react'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
export default function WhyLabs() {
    const settings1 = {
        infinite: true,
        speed: 500,
        dots: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 5000,
        arrows: false,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 4,
            },
          },
          {
            breakpoint: 640,
            settings: {
              slidesToShow: 1,
            },
          },
        ],
      };
  return (
    <div className='w-[90%] mx-auto '>
    <Slider {...settings1}>
    <div className='SLIDE-1 px-2 lg:h-[550px] h-[550px]'>
    <div className='flex-shrink-0  relative flex items-start group'>
    <div className='opacity-100 group-hover:opacity-0 duration-700 bg-[#0E1837] border-[2px] border-[#4F93FF] px-7 pt-7 w-full'>
      <div className='fa-bounce'><img src='/assets/Subtract (1).png' className='mx-auto rotate-180'></img></div>
      <img src='/assets/CE_01.png' className='mx-auto w-[75%] mt-5'></img>
      <p className='mt-5 uppercase font-semibold text-white/55 tracking-widest text-center'>Advanced Web3   </p>
      <p className=' uppercase font-semibold text-[#A4C7FF] tracking-widest text-center'> Stack</p>
      <div className='flex justify-center'>
        <p className='mt-2 w-[25%] font-bold text-[#E8BA3C] border-b-2 border-[#4F93FF] pb-7 text-center'>01</p>
      </div>
    </div>
    <div className='absolute opacity-100 lg:opacity-0 group-hover:opacity-100 duration-700 bg-[#1B2D65]  border-[2px] border-[#4F93FF] px-2 pt-2'>
      <img src='/assets/why2.png' className='mx-auto'></img>
      <p className='text-white/55 my-3 text-center'>Expertise in Smart Contracts, zk-Rollups, AI/ML frameworks, Quantum computing Cryptography, and XR (Extended Reality) integrations.
      </p>
      <p className='uppercase font-semibold tracking-widest text-center'>Advanced Web3 <br /> Stack  </p>
      <div className='flex justify-center'>
        <p className='mt-2 w-[50%] font-bold text-[#4F93FF] border-b-2 text-center border-[#E8BA3C] pb-5'>01</p>
      </div>
    </div>
  </div>
    </div>
    
      <div className='SLIDE-2 px-2'>
      <div className='flex-shrink-0  relative flex items-start group'>
      <div className='opacity-100 group-hover:opacity-0 duration-700 bg-[#0E1837] border-[2px] border-[#4F93FF] px-7 pt-7 w-full ' >
        <div className='fa-bounce'><img src='/assets/Subtract (1).png' className='mx-auto rotate-180'></img></div>
        <img src='/assets/CE_01.png' className='mx-auto w-[75%] mt-5'></img>
        <p className='mt-5 uppercase font-semibold text-white/55 tracking-widest text-center'>Ideation to </p>
        <p className=' uppercase font-semibold text-[#A4C7FF] tracking-widest text-center'>Decentralization</p>
        <div className='flex justify-center'>
          <p className='mt-2 w-[25%] font-bold text-[#E8BA3C] border-b-2 border-[#4F93FF] pb-7 text-center'>02</p>
        </div>
      </div>
      <div className='absolute opacity-100 lg:opacity-0 group-hover:opacity-100 duration-700 bg-[#1B2D65]  border-[2px] border-[#4F93FF] px-2 pt-2'>
        <img src='/assets/why1.png' className='mx-auto'></img>
        <p className='text-white/55 my-3 text-center'>From zero to dApp, we transform innovative concepts into scalable, blockchain-native products.
        </p>
        <p className='uppercase font-semibold tracking-widest text-center'>Ideation to Decentralization</p>
        <div className='flex justify-center'>
          <p className='mt-2 w-[50%] font-bold text-[#4F93FF] border-b-2 text-center border-[#E8BA3C] pb-10'>02</p>
        </div>
      </div>
    </div>
      </div>
     
      <div className='SLIDE-3 px-2'>
      <div className='flex-shrink-0  relative flex items-start group'>
      <div className='w-full opacity-100 group-hover:opacity-0 duration-700 bg-[#0E1837] border-[2px] border-[#4F93FF] px-7 pt-7'>
        <div className='fa-bounce'><img src='/assets/Subtract (1).png' className='mx-auto rotate-180'></img></div>
        <img src='/assets/CE_03.png' className='mx-auto w-[75%] mt-5'></img>
        <p className='mt-5 uppercase font-semibold text-white/55 tracking-widest text-center'> Asset Tokenization </p>
        <p className=' uppercase font-semibold text-[#A4C7FF] tracking-widest text-center'>Protocols</p>
        <div className='flex justify-center'>
          <p className='mt-2 w-[25%] font-bold text-[#E8BA3C] border-b-2 border-[#4F93FF] pb-7 text-center'>03</p>
        </div>
      </div>
      <div className='absolute opacity-100 lg:opacity-0 group-hover:opacity-100 duration-700 bg-[#1B2D65]  border-[2px] border-[#4F93FF] px-2 pt-2'>
        <img src='/assets/why3.png' className='mx-auto'></img>
        <p className='text-white/55 my-3 text-sm text-center'>Convert real-world and digital assets into tokenized formats, enabling fractional ownership, seamless DeFi integration, and cross-chain interoperability.
        </p>
        <p className='uppercase font-semibold tracking-widest text-center'>Asset Tokenization  <br /> Protocols</p>
        <div className='flex justify-center'>
          <p className='mt-2 w-[50%] font-bold text-[#4F93FF] border-b-2 border-[#E8BA3C] pb-8 text-center'>03</p>
        </div>
      </div>
    </div>

      </div>
      <div className='SLIDE-4 px-2'>
      <div className='flex-shrink-0  relative flex items-start group'>
      <div className='w-full opacity-100 group-hover:opacity-0 duration-700 bg-[#0E1837] border-[2px] border-[#4F93FF] px-7 pt-7'>
        <div className='fa-bounce'><img src='/assets/Subtract (1).png' className='mx-auto rotate-180'></img></div>
        <img src='/assets/CE_04.png' className='mx-auto w-[75%] mt-5'></img>
        <p className='mt-5 uppercase font-semibold text-white/55 tracking-widest text-center'>InnoTech</p>
        <p className=' uppercase font-semibold text-[#A4C7FF] tracking-widest text-center'>Deployables</p>
        <div className='flex justify-center'>
          <p className='mt-2 w-[20%] font-bold text-[#E8BA3C] border-b-2 border-[#4F93FF] pb-7 text-center'>04</p>
        </div>
      </div>
      <div className='absolute opacity-100 lg:opacity-0 group-hover:opacity-100 duration-700 bg-[#1B2D65]  border-[2px] border-[#4F93FF] px-2 pt-2'>
        <img src='/assets/why4.png' className='mx-auto'></img>
        <p className='text-white/55 my-3 text-center'>Launch-ready InnoTech modules with plug-and-play capabilities—customizable for DAOs, DeFi, NFTs, and other Web3 ecosystems.
        </p>
        <p className='uppercase font-semibold tracking-widest text-center'>InnoTech <br /> Deployables</p>
        <div className='flex justify-center'>
          <p className='mt-2 w-[50%] font-bold text-[#4F93FF] border-b-2 border-[#E8BA3C] pb-5 text-center'>04</p>
        </div>
      </div>
    </div>

      </div>
      <div className='SLIDE-5 px-2 text-center'>
      <div className='flex-shrink-0  relative flex items-start group'>
      <div className='opacity-100 group-hover:opacity-0 duration-700 bg-[#0E1837] border-[2px] border-[#4F93FF] px-7 pt-7 w-full'>
        <div className='fa-bounce'><img src='/assets/Subtract (1).png' className='mx-auto rotate-180'></img></div>
        <img src='/assets/Group 1597883329.png' className='mx-auto w-[75%] mt-5'></img>
        <p className='mt-5 uppercase font-semibold text-white/55 tracking-widest text-center'> R&D Accelerators  
        </p>
        <p className=' uppercase font-semibold text-[#A4C7FF] tracking-widest text-center'> for Web3 </p>
        <div className='flex justify-center'>
          <p className='mt-2 w-[25%] font-bold text-[#E8BA3C] border-b-2 border-[#4F93FF] pb-7 text-center'>05</p>
        </div>
      </div>
      <div className='absolute opacity-100 lg:opacity-0 group-hover:opacity-100 duration-700 bg-[#1B2D65]  border-[2px] border-[#4F93FF] px-2 pt-2'>
        <img src='/assets/image (7).png' className='mx-auto'></img>
        <p className='text-white/55 my-3 text-center'>State-of-the-art research hubs designed for smart contract audits, protocol development, rapid prototyping, and Web3 stress testing.
        </p>
        <p className='uppercase font-semibold tracking-widest text-center'> R&D Accelerators  <br /> for Web3</p>
        <div className='flex justify-center'>
          <p className='mt-2 w-[50%] font-bold text-[#4F93FF] border-b-2 border-[#E8BA3C] pb-5 text-center'>05</p>
        </div>
      </div>
    </div>
      </div>
    
      <div className='SLIDE-6 px-2 text-center'>
      <div className='flex-shrink-0  relative flex items-start group'>
      <div className='opacity-100 group-hover:opacity-0 duration-700 bg-[#0E1837] border-[2px] border-[#4F93FF] px-7 pt-7 w-full'>
        <div className='fa-bounce'><img src='/assets/Subtract (1).png' className='mx-auto rotate-180'></img></div>
        <img src='/assets/Group 1597883329.png' className='mx-auto w-[75%] mt-5'></img>
        <p className='mt-5 uppercase font-semibold text-white/55 tracking-widest text-center'>Global Web3 
        </p>
        <p className=' uppercase font-semibold text-[#A4C7FF] tracking-widest text-center'>Network</p>
        <div className='flex justify-center'>
          <p className='mt-2 w-[25%] font-bold text-[#E8BA3C] border-b-2 border-[#4F93FF] pb-7 text-center'>06</p>
        </div>
      </div>
      <div className='absolute opacity-100 lg:opacity-0 group-hover:opacity-100 duration-700 bg-[#1B2D65]  border-[2px] border-[#4F93FF] px-2 pt-2'>
        <img src='/assets/image 14 (2).png' className='mx-auto'></img>
        <p className='text-white/55 my-3 text-center'>Access our global consortium of blockchain VCs, cross-chain partnerships, and decentralized funding sources. We connect innovators to liquidity pools and governance communities.
        </p>
        <p className='uppercase font-semibold tracking-widest text-center'>Global Web3 Network</p>
        <div className='flex justify-center'>
          <p className='mt-2 w-[50%] font-bold text-[#4F93FF] border-b-2 border-[#E8BA3C] pb-5 text-center'>06</p>
        </div>
      </div>
    </div>
      </div>
      
      <div className='SLIDE-7 px-2 text-center'>
      <div className='flex-shrink-0  relative flex items-start group'>
      <div className='w-full opacity-100 group-hover:opacity-0 duration-700 bg-[#0E1837] border-[2px] border-[#4F93FF] px-7 pt-7'>
        <div className='fa-bounce'><img src='/assets/Subtract (1).png' className='mx-auto rotate-180'></img></div>
        <img src='/assets/Group 1597883335.png' className='mx-auto w-[75%] mt-5'></img>
        <p className='mt-5 uppercase font-semibold text-white/55  text-center'> Decentralized Innovation  </p>
        <p className=' uppercase font-semibold text-[#A4C7FF] tracking-widest text-center'>Hub</p>
        <div className='flex justify-center'>
          <p className='mt-2 w-[25%] font-bold text-[#E8BA3C] border-b-2 border-[#4F93FF] pb-7 text-center'>07</p>
        </div>
      </div>
      <div className='absolute opacity-100 lg:opacity-0 group-hover:opacity-100 duration-700 bg-[#1B2D65]  border-[2px] border-[#4F93FF] px-2 pt-2'>
        <img src='/assets/image 15.png' className='mx-auto'></img>
        <p className='text-white/55 my-3 text-center'>Tap into a DAO-driven community of builders, investors, and early adopters, fostering a Web3-native approach to scaling.
        </p>
        <p className='uppercase font-semibold tracking-widest text-center'>Decentralized Innovation   <br /> Hub</p>
        <div className='flex justify-center'>
          <p className='mt-2 w-[50%] font-bold text-[#4F93FF] border-b-2 border-[#E8BA3C] pb-5 text-center'>07</p>
        </div>
      </div>
    </div>

      </div>
      <div className='SLIDE-8 px-2 text-center'>
      <div className='flex-shrink-0  relative flex items-start group'>
      <div className='w-full opacity-100 group-hover:opacity-0 duration-700 bg-[#0E1837] border-[2px] border-[#4F93FF] px-7 pt-7'>
        <div className='fa-bounce'><img src='/assets/Subtract (1).png' className='mx-auto rotate-180'></img></div>
        <img src='/assets/Group 1597883336.png' className='mx-auto w-[75%] mt-5'></img>
        <p className='mt-5 uppercase font-semibold text-white/55 tracking-widest text-center'>Proven Web3 </p>
        <p className=' uppercase font-semibold text-[#A4C7FF] tracking-widest text-center'>Impact</p>
        <div className='flex justify-center'>
          <p className='mt-2 w-[20%] font-bold text-[#E8BA3C] border-b-2 border-[#4F93FF] pb-7 text-center'>08</p>
        </div>
      </div>
      <div className='absolute opacity-100 lg:opacity-0 group-hover:opacity-100 duration-700 bg-[#1B2D65]  border-[2px] border-[#4F93FF] px-2 pt-2'>
        <img src='/assets/image 16.png' className='mx-auto'></img>
        <p className='text-white/55 my-3 text-center'>$10M+ in valuation, 30K+ active users, and tokenized assets with real-world utility—driving adoption in blockchain-based markets.</p>
        <p className='uppercase font-semibold tracking-widest text-center'>Proven Web3 <br /> Impact</p>
        <div className='flex justify-center'>
          <p className='mt-2 w-[50%] font-bold text-[#4F93FF] border-b-2 border-[#E8BA3C] pb-5 text-center'>08</p>
        </div>
      </div>
    </div>

      </div>
      
    </Slider>
    
 
  
  
    </div>
  )
}
